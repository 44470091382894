<template>
  <div>
    <van-nav-bar title="沃尔沃汽车" fixed left-text="" left-arrow @click-left="$router.push('/')" @touchmove.prevent
      placeholder />
    <div style="display: flex; flex-direction: column">
      <img src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg" alt="" />
      <span style="margin: 0 16pt; font-size: 13pt">沃尔沃全新纯电动及插电式混合动力型
      </span>
      <div class="contenttitle">
        <div class="contents"></div>
        <span class="contentspan">沃尔沃(Volvo)</span>
      </div>
      <span
        style="margin: 0 16pt; font-size: 13pt">瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立，瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立，瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立，瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立</span>
      <img src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg" alt="" />
      <div class="contenttitle">
        <div class="contents"></div>
        <span class="contentspan">产品卖点</span>
      </div>
      <span
        style="margin: 0 16pt; font-size: 13pt">瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立，瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立，瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立，瑞典著名豪华汽车品牌，曾译为富豪。该品牌于1927年在瑞典哥德堡建立</span>
      <img src="https://img0.baidu.com/it/u=2626690094,1650585734&fm=26&fmt=auto" alt="" />
      <div class="contenttitle">
        <div class="contents"></div>
        <span class="contentspan">预约咨询</span>
      </div>
      <div class="userinfo">
        <span>姓名</span>
        <input placeholder="请输入姓名" v-model="submitData.username" type="text" />
      </div>
      <div class="userinfo">
        <span>手机</span>
        <input placeholder="请输入手机" v-model="submitData.userphone" type="text" />
      </div>
      <div class="userinfo" @click="openarea">
        <span>地区</span>
        <input style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap" placeholder="请选择地区"
          v-model="areaValue" readonly type="text" />
        <div class="icon">
          <van-icon name="arrow" size="17" />
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin: 15pt 0">
        <div class="btn" @click="submit" :class="submitatatus == 0 ? 'active' : ''">
          <span>{{ submitatatus == 0 ? "已提交" : "提交" }}</span>
        </div>
      </div>

      <van-popup v-model="choicearea" position="bottom" round :style="{ height: '30%' }">
        <van-area title="请选择地区" :area-list="areaList" @cancel="cancel" @confirm="getarea" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      areaList,
      areaValue: "",
      //选择地区是否显示
      choicearea: false,
      //提交数据
      submitData: {
        username: "",
        userphone: "",
        arealist: {},
      },
      submitatatus: 1,
      timer: null
    };
  },
  methods: {
    submit() {
      const { username, userphone, arealist } = this.submitData;
      if (!username) {
        this.$toast("请输入姓名");
        return;
      }
      if (!userphone) {
        this.$toast("请输入手机号");
        return;
      }
      if (userphone.length !== 11) {
        this.$toast("请输入11位手机号");
        return;
      }
      if (JSON.stringify(arealist) == "{}") {
        this.$toast("请选择地区");
        return;
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.timer = setTimeout(() => {
        console.log(this.submitData);
        this.$toast.success("提交成功");
        this.submitatatus = 0;
      }, 1000);
    },
    openarea() {
      this.choicearea = true;
    },
    cancel() {
      this.choicearea = false;
    },

    getarea(value) {
      this.submitData.arealist.province_list = value[0].code;
      this.submitData.arealist.city_list = value[1].code;
      this.submitData.arealist.county_list = value[2].code;
      console.log(value);
      this.areaValue =
        value[0].name + "/" + value[1].name + "/" + value[2].name;
      this.choicearea = false;
    },
  },
  destroyed() {
    clearTimeout(this.timer)
  }
};
</script>

<style scoped>
.contenttitle {
  display: flex;
  margin: 10pt 16pt;
  align-items: center;
}

.contents {
  width: 5pt;
  height: 18pt;
  background-color: #ff5200;
}

.contentspan {
  margin-left: 10pt;
  font-size: 16pt;
  font-weight: bold;
}

.userinfo {
  margin: 5pt 16pt 5pt 16pt;
  padding: 10pt 0;
  border-bottom: 1pt solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.userinfo span {
  font-size: 15pt;
  margin-right: 15pt;
}

.userinfo input {
  width: 80%;
  font-size: 15pt;
  color: #686868;
  background-color: #f6f6f6;
}

.btn {
  width: 90%;
  height: 36pt;
  background-color: #ff7200;
  border-radius: 18pt;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13pt;
}

.btn:active {
  background-color: #ff3c00;
}

.active {
  width: 90%;
  transition-duration: 0.3s;
  color: black;
  background-color: #f6f6f6;
  border: 1pt solid #ff7200;
}
</style>